import dataManager from '@/plugins/dataManager'
import Vue from 'vue'
const state = {
  properties: {},
  groups: [],
  lookup: [],
  allLookups: []
}

// getters
const getters = {
  getSelectedLookup: (state) => (name) => {
    return state.properties[name]
  },
  lookupGroups: (state) => {
    return state.groups
  },
  getLookup: (state) => {
    return state.lookup
  },
  findLookup: (state) => (group, value) => {
    if (state.allLookups && state.allLookups.length) {
      const filteredLookups = state.allLookups.filter((lookup) => {
        return lookup.groupName === group
      })

      const foundLookup = filteredLookups.find(lookup => lookup.value === value)

      if (foundLookup) {
        return foundLookup.label
      }
    }

    return value
  }
}

// actions
const actions = {
  getLookupGroups (context, name) {
    // let scope = this
    return new Promise((resolve, reject) => {
      dataManager.get('clive', 'v1/Lookup/groups').then(function (res) {
        context.commit('setGroups', res)
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  getLookup (context, obj) {
    const data = {
      includeDescendants: obj.descendants
    }
    return new Promise((resolve, reject) => {
      dataManager.get('clive', 'v1/lookup/group/' + obj.name, data)
        .then(function (res) {
          context.commit('setSelectedLookup', { name: obj.name, data: res })
          context.commit('setAllLookups', res)
          resolve(res)
        })
        .catch(function (res) {
          reject(res)
        })
    })
  },
  checkAnnotationGroupUsed (context, id) {
    return new Promise((resolve, reject) => {
      dataManager.get('clive', 'v1/annotation/checkGroupUsed/' + id).then(function (res) {
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  deleteLookup (context, id) {
    return new Promise((resolve, reject) => {
      dataManager.delete('clive', 'v1/lookup/' + id).then(function (res) {
        resolve(res)
      }).catch(function (res) {
        reject(res)
      })
    })
  },
  saveLookup (context, data) {
    // let scope = this
    return new Promise((resolve, reject) => {
      dataManager.post('clive', 'v1/lookup/create', data)
        .then(function (res) {
          resolve(res)
        }).catch(function (res) {
          reject(res)
        })
    })
  },
  updateLookup (context, data) {
    // let scope = this
    return new Promise((resolve, reject) => {
      dataManager.put('clive', 'v1/lookup/' + data.id, data)
        .then(function (res) {
          resolve(res)
        }).catch(function (res) {
          reject(res)
        })
    })
  }
}

// mutations
const mutations = {
  setGroups (state, data) {
    state.groups = data
  },
  setLookup (state, lookup) {
    state.lookup = lookup
  },
  setSelectedLookup (state, data) {
    Vue.set(state.properties, data.name, data.data)
  },
  setAllLookups (state, data) {
    data.forEach((newLookup) => {
      const foundLookup = state.allLookups.find(lookup => lookup.groupName === newLookup.groupName && lookup.value === newLookup.value)

      if (!foundLookup) {
        state.allLookups.push(newLookup)
      }
    })
  }
}

const namespaced = true

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced
}
