<template>
  <div id="app">
    <v-app>
      <template v-if="!loading">
        <app-header></app-header>
        <v-main>
          <router-view></router-view>
        </v-main>
      </template>
      <template v-else>
        <v-container fill-height v-if="loading">
          <v-layout align-center justify-center>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-progress-circular class="justify-center" indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </template>
    </v-app>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader/AppHeader.vue'

export default {
  components: {
    'app-header': AppHeader
  },
  name: 'App',

  data () {
    return {
      loading: true
    }
  },

  async mounted () {
    const scope = this
    // Checks to see if the token is valid
    this.$router.onReady(a => {
      if (this.$route.meta && this.$route.meta.notAllowedAuthed) {
        this.loading = false
      } else if (this.$store.state.session.isLoggedIn === true && this.loading) {
        this.$store.dispatch('checkStatus').then(data => {
          scope.$store.dispatch('applicationConfiguration', window.location.host)
        })
          .catch(() => {
            scope.$store.dispatch('logout')

            // If the user gets kicked out of the system, redirect them to whatever they were trying to access
            const route = scope.$route
            if (route.fullPath) {
              scope.$router.push({ name: 'Login', query: { redirect: route.fullPath } })
            } else {
              scope.$router.push({ name: 'Login' })
            }
            scope.$snotify.info('Please log in to continue.')
          })
          .finally(() => {
            scope.loading = false
          })
      } else {
        this.$store.dispatch('logout')
        this.loading = false
      }
    })
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
