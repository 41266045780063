import dataManager from '@/plugins/dataManager'
import baseAuthentication from './baseAuthentication.vue'

export default {
  name: 'accountActivate',
  components: { 'base-authentication': baseAuthentication },
  data () {
    return {
      password: null,
      passwordConfirm: null,
      loading: false,
      showPassword: false,
      showConfirmPassword: false,
      valid: true,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      }
    }
  },
  computed: {
    passwordConfirmationRule () {
      return () => (this.password === this.passwordConfirm) || 'Password must match'
    }
  },
  methods: {
    activateAccount: function () {
      var scope = this
      scope.loading = true

      if (this.$refs.form.validate()) {
        var data = {
          userId: scope.$route.query.verify,
          token: scope.$route.query.token,
          password: scope.password,
          confirmPassword: scope.passwordConfirm
        }

        dataManager.post('clive', 'v1/account/activate', data)
          .then(function (res) {
            scope.loading = false
            scope.$snotify.success('Account has been activated')
            scope.$router.push('/login')
          })
          .catch(function (err) {
            scope.loading = false
            scope.$snotify.error(err)
            scope.valid = false
          })
      } else {
        scope.loading = false
      }
    }
  }
}
