import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import VueI18n from 'vue-i18n'
import Snotify from 'vue-snotify'
import 'vue-snotify/styles/material.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

// Global template filters
import '@/filters/date'
import '@/filters/string'

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(Snotify)

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
