export default {
  props: {
    value: {
      required: true
    }
  },
  name: 'geo-location',
  components: {},
  data () {
    return { message: null }
  },
  watch: {},
  computed: {},
  methods: {
    getLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition, this.showError)
      } else {
        this.message = 'Geolocation is not supported by this browser.'
      }
    },
    showPosition (position) {
      this.message = 'Latitude: ' + position.coords.latitude +
        ' Longitude: ' + position.coords.longitude

      const value = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }

      this.$emit('input', value)
      this.$emit('locationHandled')
    },
    showError (error) {
      const scope = this
      switch (error.code) {
        case error.PERMISSION_DENIED:
          scope.message = 'User denied the request for Geolocation.'
          break
        case error.POSITION_UNAVAILABLE:
          scope.message = 'Location information is unavailable.'
          break
        case error.TIMEOUT:
          scope.message = 'The request to get user location timed out.'
          break
        case error.UNKNOWN_ERROR:
          scope.message = 'An unknown error occurred.'
          break
      }

      this.$emit('locationHandled')
    }
  },
  mounted () {
    this.getLocation()
  },
  created () {}
}
