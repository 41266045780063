import moment from 'moment'
import Vue from 'vue'

Vue.filter('dateTime', function (value) {
  if (!value) return '--'
  value = moment.utc(value).local().format('DD/MM/yyyy HH:mm:ss')
  return value
})

Vue.filter('date', function (value) {
  if (!value) return '--'
  value = moment.utc(value).local().format('DD/MM/yyyy')
  return value
})

Vue.filter('time', function (value) {
  if (!value) return '--'
  value = moment.utc(value).local().format('HH:mm:ss')
  return value
})
