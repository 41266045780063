import dataManager from '@/plugins/dataManager'

const state = {
  latestAttendance: null
}

// getters
const getters = {
  latestAttendance: (state) => {
    return state.latestAttendance
  }
}

// actions
const actions = {
  getLatestAttendance (context) {
    return new Promise((resolve, reject) => {
      dataManager.get('checkIn', 'attendance/latest')
        .then((res) => {
          context.commit('setLatestAttendance', res)
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  }
}

// mutations
const mutations = {
  setLatestAttendance (state, obj) {
    state.latestAttendance = obj
  }
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
