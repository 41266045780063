<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card style="text-align: center">
        <v-toolbar dark color="accent">
          <v-toolbar-title>Fit For Work</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <p class="pt-4">
            <b
              >Before you can check in please click button to confirm that you
              are COVID-19 symptom free.</b
            >
          </p>
          <p>
            <b>If you are unsure click the link below to check.</b>
          </p>
          <p>
            <b
              >If you have any of these symptoms please contact your manager and
              return home.</b
            >
          </p>
          <p>
            <a
              href="https://www.nhs.uk/conditions/coronavirus-covid-19/check-if-you-have-coronavirus-symptoms"
              _target="blank"
              >https://www.nhs.uk/conditions/coronavirus-covid-19/check-if-you-have-coronavirus-symptoms</a
            >
          </p>
          <v-card
            color="secondary pt-3"
            style="
              border-radius: 15px 15px 0 0;
              width: 100%;
              position: fixed;
              bottom: 0;
              height: 300px;
              left: 0;
            "
          >
            <v-card-title primary-title>
              <v-row row wrap>
                <v-col cols="12">
                  <h3 class="subheading mb-0 white--text text-xs-center">
                    I confirm that I am COVID-19 symptom free
                  </h3>
                </v-col>
                <v-col cols="12" class="text-xs-center pt-5">
                  <v-btn
                    @click="confirmFitForWork()"
                    color="red white--text"
                    class="text-uppercase"
                    large
                    rounded
                  >
                    Confirm
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    confirmFitForWork () {
      this.$emit('confirmed')
    }
  }
}
</script>
