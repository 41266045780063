import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import attendance from './modules/attendance'
import session from './modules/session'
import checkIn from './modules/checkIn'
import leaveManagement from './modules/leaveManagement'
import site from './modules/site'
import timesheet from './modules/timesheet'
import client from './modules/client'
import lookups from './modules/lookups'
import leaveTypes from './modules/leaveTypes'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['session']
    })
  ],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    client,
    attendance,
    session,
    checkIn,
    leaveManagement,
    site,
    timesheet,
    lookups,
    leaveTypes
  }
})
