import dataManager from '@/plugins/dataManager'

const state = {
  sites: []
}

// getters
const getters = {
  getSite: (state) => (id) => {
    return state.sites.find(s => s.id === id)
  }
}

// actions
const actions = {
  getSiteForJob (context, id) {
    return new Promise((resolve, reject) => {
      dataManager.get('checkIn', `job/${id}/site`)
        .then((res) => {
          context.commit('setSite', res)
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  }
}

// mutations
const mutations = {
  setSite (state, site) {
    state.sites.push(site)
  }
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
