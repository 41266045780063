export default {
  name: 'AppHeader',
  data () {
    return {}
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.session.isLoggedIn
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      this.$router.push({ name: 'Login' })
    }
  }
}
