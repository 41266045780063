
import dataManager from '@/plugins/dataManager'

const state = {
  employeeTimesheets: [],
  userTimesheetsApprover: [],
  allowedDates: []
}

// getters
const getters = {
  getEmployeeTimesheets: (state) => {
    return state.employeeTimesheets
  },
  getAllowedDates (state) {
    return state.allowedDates
  },
  getSelectedTimesheetsDate (state) {
    return state.selectedTimesheetsDate
  },
  getSelectedTimesheetsEndDate (state) {
    return state.selectedTimesheetsEndDate
  },
  getUserTimesheetsApprover (state) {
    return state.userTimesheetsApprover
  }
}

// actions
const actions = {
  // get time for a single employee
  retrieveEmployeeTimesheets (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.get('clive', 'v1/employee/timesheet/my', params)
        .then(function (data) {
          context.commit('setEmployeeTimesheets', data)
          resolve(data)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  retrieveAllowedDates (context) {
    return new Promise((resolve, reject) => {
      dataManager.get('clive', 'v1/employee/allowed-dates-pwa').then(function (data) {
        context.commit('setAllowedDates', data)
        resolve(data)
      }).catch(function (err) {
        reject(err)
      })
    })
  },
  isWeekApproved (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.get('clive', `v1/employee/is_week_approved?startDate=${params.startDate}&endDate=${params.endDate}`).then(function (res) {
        resolve(res)
      }).catch(function (err) {
        reject(err)
      })
    })
  },
  isWeekSubmittedApproved (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.get('clive', `v1/employee/is-week-sumitted-approved?&endDate=${params.endDate}`).then(function (res) {
        resolve(res)
      }).catch(function (err) {
        reject(err)
      })
    })
  },
  timesheetApprover (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.get('clive', `v1/employee/timesheet-approver?endDate=${params.endDate}`).then(function (data) {
        context.commit('setUserTimesheetsApprover', data)
        resolve(data)
      }).catch(function (err) {
        reject(err)
      })
    })
  },
  submitEmployeeWeekly (context, obj) {
    return new Promise((resolve, reject) => {
      dataManager.post('clive', 'v1/employee/submitEmployeeWeekly', obj).then(function (res) {
        resolve(res)
      }).catch(function (err) {
        reject(err)
      })
    })
  },
  unSubmitEmployeeWeekly (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.delete('clive', `v1/employee/un-submit-employee-weekly?endDate=${params.endDate}&id=${params.id}`).then(function (res) {
        resolve(res)
      }).catch(function (err) {
        reject(err)
      })
    })
  },
  createAttendance (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.post('clive', 'v1/employee/attendance/my', params.attendance).then(function (data) {
        resolve(data)
      }).catch(function (err) {
        reject(err)
      })
    })
  },
  updateAttendance (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.put('clive', 'v1/employee/attendance/my', params.attendance).then(function (data) {
        resolve(data)
      }).catch(function (err) {
        reject(err)
      })
    })
  }
}

// mutations
const mutations = {
  setEmployeeTimesheets (state, timesheets) {
    state.employeeTimesheets = timesheets
  },
  setAllowedDates (state, dates) {
    state.allowedDates = dates
  },
  setSelectedTimesheetsDate (state, date) {
    state.selectedTimesheetsDate = date
  },
  setSelectedTimesheetsEndDate (state, date) {
    state.selectedTimesheetsEndDate = date
  },
  setUserTimesheetsApprover (state, approver) {
    state.userTimesheetsApprover = approver
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
