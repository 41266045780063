import dataManager from '@/plugins/dataManager'

const state = {
  user: {},
  token: null,
  isLoggedIn: false,
  isDirty: false,
  rememberEmail: null,
  browserId: null,
  online: true,
  selectedHeatExchanger: null,
  permissions: {
    routes: [],
    keys: [],
    defaultRoute: ''
  },
  userRole: null,
  reloadCalculations: false,
  applicationConfiguration: null
}

// getters
const getters = {
  getUserRole: (state) => {
    return state.userRole
  },
  getUser: (state) => {
    return state.user
  },
  getToken: (state) => {
    return state.token
  },
  isLoggedIn: (state) => {
    return state.isLoggedIn
  },
  getBrowserId: (state) => {
    return state.browserId
  },
  isDirty: (state) => {
    return state.isDirty
  },
  hasRoute: (state) => (key) => {
    return state.permissions.routes.includes(key)
  },
  hasPermissionKey: (state) => (key) => {
    // Check if the key and keys have been set yet.
    // If they haven't default false to make sure nothing shown that shouldn't be.
    if (key && state.permissions.keys && state.permissions.keys.length > 0) {
      // If the keys include the key return true, otherwise default false.
      return state.permissions.keys.includes(key)
    }

    // Default to false if there are no keys to make sure mistakes don't show
    // things that user shouldn't see.
    return false
  },
  getDefaultRoute: (state) => {
    return state.permissions.defaultRoute
  },
  onlineStatus: (state) => {
    return state.online
  },
  getSelectedHeatExchanger: (state) => {
    return state.selectedHeatExchanger
  },
  getReloadCalculations: (state) => {
    return state.reloadCalculations
  },
  getApplicationConfiguration: (state) => {
    return state.applicationConfiguration
  }
}

// actions
const actions = {
  saveUser (context, userData) {
    const scope = this
    return new Promise((resolve, reject) => {
      dataManager.put('clive', `v1/user/${context.state.user.id}`, userData)
        .then(function (data) {
          context.commit('setSessionUser', data)
          scope._vm.$snotify.success('User Profile updated successfully')
          resolve(data)
        })
        .catch(function (err) {
          scope._vm.$snotify.error(err)
          reject(err)
        })
    })
  },

  checkStatus (context) {
    return new Promise((resolve, reject) => {
      dataManager.get('checkIn', 'auth/check-status')
        .then((data) => {
          // If we have a response from the server, we are online
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  logout (context) {
    context.commit('setIsLoggedIn', false)
    context.commit('setSessionUser', {})
    context.commit('setPermissions', { routes: {}, keys: {} })
    context.commit('setToken', null)
  },

  applicationConfiguration (context, domain) {
    const scope = this
    return new Promise((resolve, reject) => {
      dataManager.get('clive', 'v1/auth/config')
        .then(function (data) {
          // Only set configuration if configuration is present for domain
          if (data && data[domain]) {
            context.commit('setApplicationConfiguration', data[domain])
          }

          resolve(data)
        })
        .catch(function (err) {
          scope._vm.$snotify.error(err)
          reject(err)
        })
    })
  }
}

// mutations
const mutations = {
  setUserRole (state, role) {
    state.userRole = role
  },
  setSessionUser (state, data) {
    state.user = data
  },
  setToken (state, token) {
    state.token = token
  },
  setIsLoggedIn (state, bool) {
    state.isLoggedIn = bool
  },
  setIsDirty (state, bool) {
    state.isDirty = bool
  },
  setPermissions (state, data) {
    state.permissions = data
  },
  setBrowserId (state, data) {
    state.browserId = data
  },
  setOnline (state, bool) {
    state.online = bool
  },
  setSelectedHeatExchanger: (state, data) => {
    state.selectedHeatExchanger = data
  },
  setReloadCalculations: (state, value) => {
    state.reloadCalculations = value
  },
  setApplicationConfiguration: (state, value) => {
    state.applicationConfiguration = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
