import dataManager from '@/plugins/dataManager'

const state = {
  userDetails: null,
  userCalendarEvents: []
}

// getters
const getters = {
  userDetails: (state) => {
    return state.userDetails
  },

  userCalendarEvents: (state) => {
    return state.userCalendarEvents
  }
}

// actions
const actions = {
  getUserDetails (context) {
    return new Promise((resolve, reject) => {
      dataManager.get('clive', 'v1/leaveManagement/user')
        .then((res) => {
          context.commit('setUserDetails', res)
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  },

  getUserCalendarEvents (context, params) {
    return new Promise((resolve, reject) => {
      let url = `v1/leaveManagement/user-calendar-events/${params.fromDateTime}/${params.toDateTime}`

      // If the request is for a specific user, append the ID to the request url
      // Otherwise it defaults to the user currently logged in
      if (params.userId) {
        url += `/${params.userId}`
      }

      dataManager.get('clive', url)
        .then((res) => {
          context.commit('setUserCalendarEvents', res)
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  },

  makeLeaveRequest (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.post('clive', 'v1/leaveManagement/leave-request', params)
        .then((res) => {
          context.commit('setUserDetails', res)
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  },

  putApproveRequest (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.put('clive', `v1/leaveManagement/leave-request/${params.requestId}`, params.body)
        .then((res) => {
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  },

  deleteLeaveRequest (context, id) {
    return new Promise((resolve, reject) => {
      dataManager.delete('clive', `v1/leaveManagement/leave-request/${id}`)
        .then((res) => {
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  }
}

// mutations
const mutations = {
  setUserDetails (state, obj) {
    state.userDetails = obj
  },

  setUserCalendarEvents (state, arr) {
    state.userCalendarEvents = arr
  }
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
