import dataManager from '@/plugins/dataManager'

const state = {
  leaveTypes: []
}

const getters = {
  getLeaveTypes: (state) => {
    return state.leaveTypes
  }
}

const actions = {
  getLeaveTypes (context) {
    return new Promise((resolve, reject) => {
      dataManager.get('clive', 'v1/leaveTypes', null).then(function (data) {
        context.commit('setLeaveTypes', data)
        resolve(data)
      }).catch(function (err) {
        reject(err)
      })
    })
  }
}
const mutations = {
  setLeaveTypes (state, leaveTypes) {
    state.leaveTypes = leaveTypes
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
