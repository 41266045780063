import dataManager from '@/plugins/dataManager'

// TokenHelper function to parse JWT
const TokenHelper = {
  parseJwt: function (token) {
    // Decode the JWT token by splitting it into its 3 parts (header, payload, signature)
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
    return JSON.parse(jsonPayload)
  }
}

export default {
  data () {
    return {
      user: {
        username: '',
        password: '',
        deviceId: ''
      },
      loading: false,
      showPassword: false,
      formRules: {
        required: [
          v => !!v || 'This field is required'
        ]
      }
    }
  },
  methods: {
    login () {
      const scope = this
      scope.loading = true
      const data = {
        username: scope.user.username,
        password: scope.user.password,
        deviceId: scope.$store.getters.getBrowserId
      }
      dataManager.post('checkIn', 'auth/login', data)
        .then((data) => {
          scope.$store.commit('setIsLoggedIn', true)
          scope.$store.commit('setSessionUser', data.user)
          scope.$store.commit('setPermissions', data.permissions)
          scope.$store.commit('setToken', data.token)

          // Decode the JWT and extract the role
          const parsedToken = TokenHelper.parseJwt(data.token)
          const roleKey = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'

          if (parsedToken && parsedToken[roleKey]) {
            scope.$store.commit('setUserRole', parsedToken[roleKey])
          }

          if (!scope.$route.query.redirect) {
            scope.$router.push({ name: 'CheckIn' })
          } else {
            scope.$router.push(scope.$route.query.redirect)
          }
        })
        .catch((err) => {
          scope.loading = false
          scope.$snotify.error(err.message)
          scope.$store.commit('setIsLoggedIn', false)
        })
    }
  }
}
