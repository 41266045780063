import dataManager from '@/plugins/dataManager'

const state = {
}

// getters
const getters = {
}

// actions
const actions = {
  checkIn (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.post('checkIn', 'checkin/check-in', params)
        .then((res) => {
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  },

  checkOut (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.post('checkIn', 'checkin/check-out', params)
        .then((res) => {
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  },

  updateDailyTotalsAfterCheckOut (context, params) {
    return new Promise((resolve, reject) => {
      dataManager.post('clive', 'v1/dailytotals/update-on-checkout')
        .then((res) => {
          // Call the daily totals update if check out successful
          resolve(res)
        })
        .catch((res) => {
          reject(res)
        })
    })
  }
}

// mutations
const mutations = {
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
