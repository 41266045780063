import moment from 'moment'
import 'moment/locale/en-gb'
import confirm from '@/components/confirm/confirm.vue'

export default {
  components: {
    confirm: confirm
  },
  props: ['value', 'label', 'disabled', 'fieldName', 'selectedTimesheetsDate'],
  data () {
    return {
      date: null,
      time: null,
      showDate: true,
      momentTime: '',
      loading: false,
      dateDisplay: 'Please select a date and time',
      selectedDate: null,
      dialog: false

    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('dd/MM/YYYY, HH:mm')
    }
  },
  computed: {
    textboxDate () {
      if (!this.showDate) {
        return moment.utc(this.value
        ).local().format('DD/MM/YYYY, HH:mm')
      } else {
        return null
      }
    }
  },
  methods: {
    onConfirm () {
      this.dialog = false
      this.$emit('input', this.selectedDate)
    },
    cancel () {
      const self = this
      this.$refs.confirm.open('Cancel Edit', 'Are you sure want to cancel? Any unsaved work will be lost.', { color: 'primary' }).then((confirm) => {
        if (confirm) {
          self.dialog = false
        }
      })
    },
    // Time range for which check in and check out times can be edited
    allowedDates (val) {
      const startOfWeek = new Date(this.selectedTimesheetsDate)
      let timeRange = ''
      const todaysDate = moment().format('YYYY/MM/DD')
      startOfWeek.setDate(startOfWeek.getDate() - 6).toLocaleString()
      const formattedStartOfWeek = moment(startOfWeek).format('YYYY-MM-DD')
      // Check if current week else it's previous week and use full week
      if (moment(todaysDate).isBetween(formattedStartOfWeek, this.selectedTimesheetsDate)) {
        timeRange = val <= new Date().toISOString().substr(0, 10) && val >= formattedStartOfWeek
      } else {
        timeRange = val <= this.selectedTimesheetsDate.substr(0, 10) && val >= formattedStartOfWeek
      }
      return timeRange
    }
  },
  created () {
    this.loading = true
    if (this.value != null) {
      this.selectedDate = Object.assign({}, this.value
      )
      this.date = moment.utc(this.value
      ).local().format('YYYY-MM-DD')
      this.time = moment.utc(this.value
      ).local().format('HH:mm')
    }
  },
  mounted () {
    this.showDate = true
    this.loading = false
  },
  watch: {
    date () {
      if (this.loading === false) {
        this.showDate = false
      }
      const convertedDate = moment(this.date)
      if (convertedDate.isValid()) {
        this.selectedDate = convertedDate

        this.momentTime = moment(this.time, 'HH:mm')
        if (this.momentTime.isValid()) {
          this.selectedDate.set({
            hour: this.momentTime.get('hour'),
            minute: this.momentTime.get('minute')
          })
        }
      }
    },
    time () {
      this.selectedDate = moment(this.date)
      this.momentTime = moment(this.time, 'HH:mm')
      if (this.momentTime.isValid()) {
        this.selectedDate.set({
          hour: this.momentTime.get('hour'),
          minute: this.momentTime.get('minute')
        })
      }
    },
    selectedDate () {
      this.dateDisplay = moment(this.selectedDate).format('LLL')
    },
    dialog (val) {
      if (val) {
        this.showDate = true
        this.selectedDate = Object.assign({}, this.value)
        this.date = moment.utc(this.value
        ).local().format('YYYY-MM-DD')
        this.time = moment.utc(this.value
        ).local().format('HH:mm')
      }
    }
  }
}
