/* eslint-disable import/no-duplicates */
import Vue from 'vue'
import VueRouter from 'vue-router'
import CheckIn from '@/views/CheckIn/CheckIn.vue'
import LeaveManagement from '@/views/LeaveManagement/LeaveManagement.vue'
import Login from '@/views/Login/Login.vue'
import NotFound from '@/views/NotFound.vue'
import timesheet from '@/views/timesheet/timesheet.vue'
import AccountActivate from '@/views/accountActivate/accountActivate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: NotFound
  },
  {
    path: '/notFound',
    name: 'notFound',
    component: NotFound
  },
  {
    path: '/',
    component: Login,
    meta: { notAllowedAuthed: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { notAllowedAuthed: true }
  },
  {
    path: '/checkin',
    name: 'CheckIn',
    component: CheckIn,
    meta: { requiresAuth: true },
    children: [
      // handles there not being a secret
      {
        path: ':siteId',
        name: 'CheckInOut',
        component: CheckIn,
        meta: { requiresAuth: true }
      },
      // handles there being at least some form of secret
      {
        path: ':siteId/:secret',
        name: 'CheckInOut',
        component: CheckIn,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/leave-management',
    name: 'LeaveManagement',
    component: LeaveManagement,
    meta: { requiresAuth: true }
  },
  {
    path: '/timesheet',
    name: 'timesheet',
    component: timesheet,
    meta: { requiresAuth: true }
  },
  {
    path: '/activate_account',
    name: 'accountActivate',
    component: AccountActivate,
    meta: { notAllowedAuthed: true }
  }
]

const routeCheck = function (to, from, next) {
  const token = router.app.$store.getters.getToken
  // If required auth
  if (to.meta.requiresAuth) {
    if (!token) {
      routeBack(to, from)
    } else {
      next()
    }
  } else if (to.meta.notAllowedAuthed) {
    if (!token) {
      next()
    } else {
      router.app.$router.push({ name: 'CheckIn' })
    }
  } else {
    next()
  }
}

const routeBack = function (to, from) {
  if (from.matched.length === 0) {
    router.app.$router.push({ name: 'Login', query: { redirect: to.fullPath } })
    router.app.$store.commit('setIsLoggedIn', false)
    router.app.$store.commit('setSessionUser', {})
    router.app.$store.commit('setPermissions', { routes: {}, keys: {} })
    router.app.$store.commit('setToken', null)
  } else {
    router.app.$router.push({ name: 'notFound' })
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  routeCheck(to, from, next)
})

export default router
